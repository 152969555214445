import {customElement, bindable, inject, BindingEngine} from "aurelia-framework";
import {AbstractCell} from './abstract-cell';
import * as _ from "lodash";
import {LocaleService} from "../../i18n/locale-service";

@customElement('sio-cell-translatable')
@inject(LocaleService, BindingEngine)
export class Translatable extends AbstractCell
{
    @bindable column;
    @bindable item;

    constructor(localeService, bindingEngine) {
        super();

        this.localeService = localeService;
        this.bindingEngine = bindingEngine;
    }

    itemChanged()
    {
        this.bind();
    }

    bind()
    {
        this.value = this.getValue();
        this.options = _.assign({}, this._defaultOptions, this.column.options);

        const multiple = this.column?.options?.multiple;

        if (multiple) {
            this.locales = this.value && this.value.length > 0 ? Object.keys(this.value[0]) : [];
        } else {
            this.locales = this.value ? Object.keys(this.value) : [];
        }

        this.setValue(this.localeService.contentLocale);
    }

    attached()
    {
        this.subscription = this.bindingEngine.propertyObserver(this.localeService, 'contentLocale').subscribe((newValue) => {
            this.setValue(newValue);
        });
    }

    detached()
    {
        if (this.subscription) {
            this.subscription.dispose();
        }
    }

    setValue(locale)
    {
        const multiple = this.column?.options?.multiple;
        const originalValue = multiple ? this.value : (this.value ? [this.value] : []);
        let value = [];

        for (const val of originalValue) {
            if (val[locale] != null) {
                value.push(val[locale]);
            }
        }
        this.currentValue = value;
    }
}
