import {customElement, bindable, inject} from 'aurelia-framework';
import {DialogService} from 'aurelia-dialog';
import {InlineEditorDialog} from "./inline-editor-dialog/inline-editor-dialog";

@customElement('sio-cell')
@inject(
    DialogService
)
export class Cell
{
    @bindable column;
    @bindable item;
    //Optional complete list
    @bindable list;

    constructor(dialog)
    {
        this.dialog = dialog;
    }

    /*public*/ openInlineEditor()
    {
        this.dialog.open(
            {
                viewModel: InlineEditorDialog,
                model: {
                    column: this.column,
                    item: this.item,
                },
                lock: true,
                overlayDismiss: false
            }
        ).whenClosed();
    }
}
