import {DialogController} from 'aurelia-dialog';
import {inject, LogManager} from "aurelia-framework";
import {EventAggregator} from "aurelia-event-aggregator";
import {Client} from "../../api/client";
import {FormField} from '../../form/object/form-field';
import * as _ from "lodash";

const logger = LogManager.getLogger('InlineEditorDialog');

@inject(
    DialogController,
    Client,
    EventAggregator
)
export class InlineEditorDialog
{
    constructor(
        dialogController,
        client,
        ea
    ) {
        this.dialogController = dialogController;
        this.client = client;
        this.ea = ea;
    }

    async activate(context) {
        this.loading = true;

        this.column = context.column;
        this.item = context.item;

        const initialValue = this.item[this.column.property];

        this.client.get('form/config/' + this.column.inlineEditor.form).then(
            formConfig => {
                const fieldConfig = _.find(formConfig.fields, field => {
                    return field.property === this.column.property;
                });

                logger.debug('Create inline editor dialog - column', this.column);
                logger.debug('Create inline editor dialog - item', this.item);
                logger.debug('Create inline editor dialog - form field config', fieldConfig);
                logger.debug('Create inline editor dialog - initial value', initialValue);

                this.field = new FormField(
                    this,
                    fieldConfig,
                    this.column.property,
                    initialValue
                );

                this.loading = false;
            }
        );
    }

    change(value)
    {
        // Do nothing
    }

    async ok() {
        const request = {
            [this.column.property]: this.field.getValue()
        };

        return this.client.patch(
            this.column.inlineEditor.form + '/' + this.item.id,
            request
        ).then(
            response => {
                this.ea.publish('sio_form_post_submit', {config: {modelId: this.item.modelId}});
                this.dialogController.ok();
            }
        ).catch(
            error => {
                logger.debug('Caught error response', error);
                // TODO: Implement validation errors handling
            }
        );
    }
}